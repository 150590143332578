import React from 'react';
import Layout from '../layouts/Layout/Layout';
import { Seo, SocialBar } from '../components';
import {
  SectionWelcomeToTheFuture,
  SectionStartAndLand,
  SectionSafety,
  SectionRevolution,
  SectionTechSpec, SectionTwo, SectionOrderYourOwn,
} from '../layouts/sections';

interface IndexPageProps {
  location: Location;
}

const IndexPage = ({ location }: IndexPageProps) => (
  <Layout location={location}>
    <Seo />
    <SocialBar />
    <SectionWelcomeToTheFuture />
    <SectionTwo />
    <SectionSafety />
    <SectionStartAndLand />
    <SectionTechSpec />
    <SectionOrderYourOwn />
  </Layout>
);

export default IndexPage;
